
            @import "@/assets/sass/vars.portal.scss";
          













































































































































































































.flavor-title {
    //color: white;
    font-weight: bold;
    font-size: 24px;
}
.logo {
    img {
        max-width: 190px;
    }

    @media (max-width: 580px) {
        img {
            max-width: 200px;
        }
    }
}

.btn-microsoft {
    border-color: $border-color;

    &:hover {
        border-color: $primary;
    }
}

.input-group-alternative {
    border: 1px solid $border-color;
    box-shadow: none;

    &:hover,
    &:focus-within {
        box-shadow: 0 1px 3px rgb(50 50 93 / 15%), 0 1px 0 rgb(0 0 0 / 2%);
        border-color: $primary;
    }
}

.card {
    &-header {
        border-bottom: none;
    }

    &-body {
        padding-top: 20px !important;
    }
}
